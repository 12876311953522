// Icons
import { ContableIcon, NavieraIcon, PuertoIcon, ViajeIcon } from './components/icons';
import { AgenteMaritimoIcon, BuqueIcon, ImportarIcon, LoteIcon } from './components/icons';

// Constants
import { PARAMETROS_URL } from '../../config';
import { ROUTES } from '../../constants/routes-constants';
import { PERMISSIONS } from '../../constants/permissions';

// Interfaces
import type { IUser } from '../../interfaces/user';
import type { ProLayoutProps } from '@ant-design/pro-layout';

const iconStyles = { marginLeft: 0 };

const getLoteSubRoutes = (
  routes: NonNullable<ProLayoutProps['route']>[],
  isMobile: boolean,
  permissions: IUser['permissions']
) => {
  const loteSubRoutes: NonNullable<ProLayoutProps['route']>[] = [];

  if (
    permissions.some((p) =>
      [
        PERMISSIONS.add_lotes,
        PERMISSIONS.edit_lotes,
        PERMISSIONS.browse_lotes,
        PERMISSIONS.validate_lotes
      ].includes(p)
    )
  ) {
    loteSubRoutes.push({
      key: 'carga-manual',
      name: 'Carga manual',
      path: ROUTES.LOTES_ROUTE,
      icon: <LoteIcon color={isMobile ? '#838383' : 'white'} style={iconStyles} />
    });
  }

  if (permissions.some((p) => [PERMISSIONS.import_lotes].includes(p))) {
    loteSubRoutes.push({
      key: 'importacion',
      name: 'Subir planilla monitor',
      path: ROUTES.IMPORTACION_LOTES_ROUTE,
      icon: <ImportarIcon color={isMobile ? '#838383' : 'white'} style={iconStyles} />
    });
  }

  if (permissions.some((p) => [PERMISSIONS.browse_lotes, PERMISSIONS.browse_viajes].includes(p))) {
    loteSubRoutes.push({
      key: 'viajes',
      name: 'Estadías cargadas',
      path: ROUTES.VIAJES_ROUTE,
      icon: <ViajeIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  if (loteSubRoutes.length) {
    routes.push({
      key: 'lote',
      path: ROUTES.LOTES_ROUTE,
      name: 'Operaciones mensuales',
      icon: <LoteIcon color={isMobile ? '#838383' : 'white'} style={iconStyles} />,
      children: loteSubRoutes
    });
  }
};

const getContableRoutes = (
  routes: NonNullable<ProLayoutProps['route']>[],
  isMobile: boolean,
  permissions: IUser['permissions']
) => {
  const contableSubRoutes: NonNullable<ProLayoutProps['route']>[] = [];

  const tables = ['balances', 'presupuestos', 'saldos'] as const;
  const tablesNames = [
    'Estado de Situación Patrimonial (ESP)',
    'Presupuestos',
    'Estado de Flujos de Efectivo (EFE)'
  ] as const;

  tables.forEach((table, index) => {
    const tablePermissions = permissions.filter((p) =>
      [PERMISSIONS[`browse_${table}`], PERMISSIONS[`import_${table}`]].includes(p)
    );

    if (tablePermissions.length) {
      const children = [];
      if (tablePermissions.some((p) => [PERMISSIONS[`browse_${table}`]].includes(p))) {
        children.push({
          key: table,
          path: ROUTES[`${table.toUpperCase() as 'BALANCES' | 'PRESUPUESTOS' | 'SALDOS'}_ROUTES`],
          name:
            tablePermissions.length > 1
              ? 'Carga manual'
              : `${table.charAt(0).toUpperCase()}${table.slice(1)}`
        });
      }

      if (tablePermissions.some((p) => [PERMISSIONS[`import_${table}`]].includes(p))) {
        children.push({
          key: `${table}-importacion`,
          path: ROUTES[
            `${table.toUpperCase() as 'BALANCES' | 'PRESUPUESTOS' | 'SALDOS'}_IMPORT_ROUTE`
          ],
          name: tablePermissions.length > 1 ? 'Subir planilla' : `Subir planilla ${table}`
        });
      }

      if (children.length === 1) {
        routes.push(...children);
      } else {
        contableSubRoutes.push({
          children,
          key: `${table}-contable`,
          name: tablesNames[index]
        });
      }
    }
  });

  if (contableSubRoutes.length)
    routes.push({
      key: 'contable',
      name: 'Operaciones contables',
      icon: <ContableIcon color={isMobile ? '#838383' : 'white'} style={iconStyles} />,
      children: contableSubRoutes
    });
};

const getParametrosRoutes = (
  routes: NonNullable<ProLayoutProps['route']>[],
  isMobile: boolean,
  permissions: IUser['permissions'],
  user: IUser
) => {
  if (
    permissions.some((p) =>
      [
        PERMISSIONS.add_barcos,
        PERMISSIONS.edit_barcos,
        PERMISSIONS.read_barcos,
        PERMISSIONS.browse_barcos
      ].includes(p)
    )
  ) {
    routes.push({
      key: 'buques',
      name: 'Buques cargados',
      path: ROUTES.BUQUES_ROUTE,
      icon: <BuqueIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  if (permissions.some((p) => [PERMISSIONS.add_puertos, PERMISSIONS.edit_puertos].includes(p))) {
    routes.push({
      key: 'puertos',
      name: 'Puertos',
      path: ROUTES.PUERTOS_ROUTE,
      icon: <PuertoIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  if (
    permissions.some((p) => [PERMISSIONS.add_agentes_maritimos].includes(p)) &&
    user.roles.find((r) => r.name === 'puertos')
  ) {
    routes.push({
      path: '/',
      key: 'agentes-maritimos',
      name: 'Agentes marítimos',
      href: `${PARAMETROS_URL}/agentes-maritimos`,
      icon: <AgenteMaritimoIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }

  if (
    permissions.some((p) => [PERMISSIONS.add_navieras].includes(p)) &&
    user.roles.find((r) => r.name === 'puertos')
  ) {
    routes.push({
      path: '/',
      key: 'navieras',
      name: 'Navieras',
      href: `${PARAMETROS_URL}/navieras`,
      icon: <NavieraIcon color={isMobile ? '#838383' : 'white'} style={{ marginLeft: -2 }} />
    });
  }
};

export default (isMobile: boolean, user: IUser) => {
  const routes: NonNullable<ProLayoutProps['route']>[] = [];

  const { permissions } = user;

  // Lote
  getLoteSubRoutes(routes, isMobile, permissions);
  getContableRoutes(routes, isMobile, permissions);

  getParametrosRoutes(routes, isMobile, permissions, user);

  return { routes };
};
